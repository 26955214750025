body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body,
#root {
  height: 100%;
}
.ant-checkbox-group-item {
  white-space: nowrap;
}

.modal-big {
  width: 100% !important;
}

.iconaLogout:hover {
  color: red;
}

.reflex-splitter {
  width: 0.5rem !important;
}

.reflex-splitter:hover {
  background-color: #1899d0 !important;
}
